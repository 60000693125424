<script>
import TablePage from '@/found/components/table_page';
import DetailForm from './form/detail.vue';

export default {
  extends: TablePage,
  data() {
    return {
      // requestType: 'GET',
    };
  },
  components: {
    DetailForm,
  },
  mounted() {
    this.getConfigList('consumerScan_budget_report_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'detail') {
        this.formName = 'DetailForm';
        this.modalConfig = {
          title: '明细',
          width: '80%',
        };

        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
          feeBudgetCode: row.feeBudgetCode,
        };

        this.openDrawer();
      }
    },
  },
};
</script>
